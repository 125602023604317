<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Biyografi</h3>
        <div style="position: absolute; right: 10px">
          <button @click="openHelperVideo" class="btn" title="Bilgi edin"><i class="fa fa-info-circle"  style="font-size:25px;color: rgb(187, 116, 148);" ></i></button>
          <div
            class="modal fade"
            id="videoHelper"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <video controls>
                  <source
                    src="https://storage.terapivitrini.com/storage/profile/biyografi.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="isLoad">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Hakkımda</label>
              <div id="content"></div>
            </div>
          </div>

          <div class="col-md-6" v-if="$store.state.user_type == 'EXPERT'">
            <label class="form-label">İlgi Alanları</label>
            <div class="form-group input-group">
              <input
                rows="5"
                name="skill"
                class="form-control"
                placeholder="İlgi Alanı Gir"
              />
              <button class="btn btn-primary" @click="skillAdd">Ekle</button>
            </div>
            <div class="w-100">
              <div
                v-for="(item, i) in skillList"
                :key="i"
                style="margin: 0"
                class="list-group-item list-group-item-action"
              >
                {{ item.title }}
                <div style="position: absolute; right: 10px; top: 10px">
                  <a
                    @click="skillRemove(i)"
                    title="Sil"
                    style="margin-left: 10px"
                    ><i class="side-menu__icon si si-trash"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="$store.state.user_type == 'EXPERT'">
            <label class="form-label">Eğitimler</label>
            <div class="input-group form-group">
              <select
                name="education"
                id="education"
                class="form-control"
                data-placeholder="Select"
                @change="educationAdd"
              >
                <option selected>Üniversite Seç</option>
                <option
                  v-for="(item, i) in education"
                  :key="i"
                  :value="item.university"
                >
                  {{ item.university }}
                </option>
              </select>
            </div>
            <div class="w-100">
              <div
                v-for="(item, i) in educationList"
                :key="i"
                style="margin: 0"
                class="list-group-item list-group-item-action"
              >
                {{ item.title }}
                <a
                  @click="educationRemove(i)"
                  title="Sil"
                  style="
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    cursor: pointer;
                  "
                  ><i class="side-menu__icon si si-trash"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="$store.state.user_type == 'EXPERT'">
            <label class="form-label">Diller</label>
            <div class="form-group input-group">
              <input
                rows="5"
                name="language"
                class="form-control"
                placeholder="Dil Gir"
              />
              <button class="btn btn-primary" @click="languageAdd">Ekle</button>
            </div>

            <div class="w-100">
              <div
                v-for="(item, i) in languageList"
                :key="i"
                style="margin: 0"
                class="list-group-item list-group-item-action"
              >
                {{ item.title }}
                <a
                  @click="languageRemove(i)"
                  title="Sil"
                  style="position: absolute; right: 10px; cursor: pointer"
                  ><i class="side-menu__icon si si-trash"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="$store.state.user_type == 'EXPERT'">
            <label class="form-label">Sertifikalar</label>
            <div class="input-group form-group">
              <input
                rows="5"
                name="certificate"
                class="form-control"
                placeholder="Sertifika Gir"
              />
              <button class="btn btn-primary" @click="certificateAdd">
                Ekle
              </button>
            </div>
            <div class="w-100">
              <div
                v-for="(item, i) in certificateList"
                :key="i"
                style="margin: 0"
                class="list-group-item list-group-item-action"
              >
                {{ item.title }}
                <div style="position: absolute; right: 10px; top: 10px">
                  <input
                    v-if="item.id"
                    type="file"
                    :id="'certificateUpload' + i"
                    :name="'certificateUpload' + i"
                    @change="certificateUploadChange(i)"
                    accept="application/pdf,image/jpeg, image/png"
                    style="display: none"
                  />
                  <!--<a
                    title="Sertifika Görüntüle"
                    :href="'https://storage.terapivitrini.com/' + item.file"
                    target="_blank"
                    v-if="item.file != null"
                    style="margin-left: 10px"
                    ><i class="fa fa-search"></i
                  ></a>
                  <a
                    @click="certificateFileUpload(i, item.id)"
                    title="Sertifika Yükle"
                    v-if="item.id && certificateUploadList[i] != null"
                    style="margin-left: 10px"
                    ><i
                      class="fa fa-upload"
                      v-if="!uploadingCertificate[i]"
                    ></i>
                    <div
                      v-if="uploadingCertificate[i]"
                      class="spinner-border"
                      role="status"
                      style="width: 15px; height: 15px"
                    >
                      <span class="sr-only">Loading...</span>
                    </div></a
                  >
                  <a
                    v-if="item.id"
                    @click="certificateUpload(i)"
                    title="Sertifika Yükle"
                    style="margin-left: 10px"
                    ><i class="fa fa-file-pdf-o"></i
                  ></a>-->
                  <a
                    @click="certificateRemove(i)"
                    title="Sil"
                    style="margin-left: 10px"
                    ><i class="side-menu__icon si si-trash"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="$store.state.user_type == 'EXPERT'">
            <label class="form-label">Bağlı Kurum Seç</label>
            <div class="input-group form-group">
              <select
                name="center"
                id="center"
                class="form-control"
                data-placeholder="Select"
                @change="centerAdd"
              >
                <option selected disabled>Kurum Seç</option>
                <option
                  v-for="(item, i) in centers"
                  :key="i"
                  :data-title="item.title"
                  :value="item.id"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
            <div class="w-100">
              <div
                v-for="(item, i) in centerList"
                :key="i"
                style="margin: 0"
                class="list-group-item list-group-item-action"
              >
                {{ item.title }}
                <a
                  @click="centerRemove(i)"
                  title="Sil"
                  style="
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    cursor: pointer;
                  "
                  ><i class="side-menu__icon si si-trash"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="isLoad">
        <button
          type="button"
          id="saveButton"
          @click="save"
          class="btn btn-primary"
        >
          Biyografi Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Biyografim";
    this.$store.dispatch("biographieGet").then((value) => {
      this.about = value.about;
      this.educationList = value.education;
      this.centerList = value.center;
      console.log(this.centerList)
      this.skillList = value.skill;
      this.languageList = value.language;
      this.certificateList = value.certificate;
      this.isLoad = true;
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://terapivitrini.com/assets/plugins/summer-note/summernote1.js"
      );
      document.head.appendChild(recaptchaScript);
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
      $(document).ready(function () {
        $("#content").summernote({
          toolbar: [
            // [groupName, [list of button]]
            ["style", ["bold", "italic", "underline", "clear"]],
            ["fontsize", ["fontsize"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["height", ["height"]],
          ],
        });
      });
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#content-app").offset().top - 60,
          },
          0
        );

        $("#content").summernote("code", this.about[0].about);
      }, 500);
    });

    this.$store.dispatch("educationGet").then((value) => {
      this.education = value;
    });
    this.$store.dispatch("centerList").then((value) => {
      this.centers = value;
    });
  },
  data() {
    return {
      education: [],
      about: [],
      centers: [],
      educationList: [],
      centerList: [],
      skillList: [],
      skillUploadList: [],
      uploadingSkill: [],
      certificateList: [],
      certificateUploadList: [],
      uploadingCertificate: [],
      languageList: [],
      isLoad: false,
    };
  },
  methods: {
    openHelperVideo() {
      $("#videoHelper").modal("show");
    },
    skillUpload(i) {
      $("#skillUpload" + i).trigger("click");
    },
    skillUploadChange(i) {
      let file = document.querySelector("input[name=skillUpload" + i + "]")
        .files[0];
      if (
        file.type == "application/pdf" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        this.skillUploadList[i] = file;
        this.skillUploadList.push({});
        this.skillUploadList.pop();
      } else {
        this.$vToastify.warning(
          "Sadece PDF,JPG ve PNG formatında dosyalar!",
          "Uyarı!"
        );
      }
    },
    skillFileUpload(i, id) {
      this.uploadingSkill[i] = true;
      this.uploadingSkill.push({});
      this.uploadingSkill.pop();
      let file = this.skillUploadList[i];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      formData.append("type", "SKILL");
      this.$store
        .dispatch("biographieFileUpload", formData)
        .then((response) => {
          this.$vToastify.success(
            "Dosya başarılı bir şekilde yüklendi",
            "Başarılı!"
          );
          this.uploadingSkill[i] = false;
          this.uploadingSkill.push({});
          this.uploadingSkill.pop();

          this.skillUploadList[i] = null;
          this.skillUploadList.push({});
          this.skillUploadList.pop();

          this.skillList[i] = response[0];
        });
    },
    skillUploadFile(i) {
      let file = document.querySelector("input[name=skillUpload" + i + "]")
        .files[0];
      if (
        file.type == "application/pdf" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        this.skillUploadList[i] = file;
        this.skillUploadList.push({});
        this.skillUploadList.pop();
      } else {
        this.$vToastify.warning(
          "Sadece PDF,JPG ve PNG formatında dosyalar!",
          "Uyarı!"
        );
      }
    },
    certificateUpload(i) {
      $("#certificateUpload" + i).trigger("click");
    },
    certificateUploadChange(i) {
      let file = document.querySelector(
        "input[name=certificateUpload" + i + "]"
      ).files[0];
      if (
        file.type == "application/pdf" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        this.certificateUploadList[i] = file;
        this.certificateUploadList.push({});
        this.certificateUploadList.pop();
      } else {
        this.$vToastify.warning(
          "Sadece PDF,JPG ve PNG formatında dosyalar!",
          "Uyarı!"
        );
      }
    },
    certificateFileUpload(i, id) {
      this.uploadingCertificate[i] = true;
      this.uploadingCertificate.push({});
      this.uploadingCertificate.pop();
      let file = this.certificateUploadList[i];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      formData.append("type", "CERTIFICATE");
      this.$store
        .dispatch("biographieFileUpload", formData)
        .then((response) => {
          this.$vToastify.success(
            "Dosya başarılı bir şekilde yüklendi",
            "Başarılı!"
          );
          this.uploadingCertificate[i] = false;
          this.uploadingCertificate.push({});
          this.uploadingCertificate.pop();

          this.certificateUploadList[i] = null;
          this.certificateUploadList.push({});
          this.certificateUploadList.pop();

          this.certificateList[i] = response[0];
        });
    },
    certificateUploadFile(i) {
      let file = document.querySelector(
        "input[name=certificateUpload" + i + "]"
      ).files[0];
      if (file.type == "application/pdf") {
        this.certificateUploadList[i] = file;
        this.certificateUploadList.push({});
        this.certificateUploadList.pop();
      } else {
        this.$vToastify.warning("Sadece PDF formatında dosyalar!", "Uyarı!");
      }
    },
    getEducationList(e) {},
    centerAdd(e) {
      let id = document.getElementById("center").value;
      let title = $("#center option:selected").attr("data-title")
      if (id == null || id == "") {
        this.$vToastify.warning("Kurum alanı boş girilemez", "Uyarı!");
      } else {
        let bool = true;
        this.centerList.forEach((e) => {
          if (e.id == id) {
            bool = false;
          }
        });
        if (bool) {
          this.centerList.push({
            title:title,
            id: id,
          });
          this.$vToastify.success("Kurum Bağlantısı Eklendi", "Başarılı!");
          document.getElementById("education").value = "";
        } else {
          this.$vToastify.warning(
            "Kurum bağlantısı zaten listende var",
            "Uyarı!"
          );
        }
      }
    },
    educationAdd(e) {
      let name = document.getElementById("education").value;
      if (name == null || name == "") {
        this.$vToastify.success("Eğitim alanı boş girilemez", "Uyarı!");
      } else {
        let bool = true;
        this.educationList.forEach((e) => {
          if (e.title == name) {
            bool = false;
          }
        });
        if (bool) {
          this.educationList.push({
            title: name,
          });
          this.$vToastify.success("Eğitim Eklendi", "Başarılı!");
          document.getElementById("education").value = "";
        } else {
          this.$vToastify.warning("Eğitim zaten listende var", "Uyarı!");
        }
      }
    },
    languageAdd(e) {
      let language = document.querySelector("input[name=language]").value;
      let bool = true;
      if (language == null || language == "") {
        this.$vToastify.success("Dil alanı boş girilemez", "Uyarı!");
      } else {
        this.languageList.forEach((t) => {
          if (t.title == language) {
            bool = false;
          }
        });
        if (bool) {
          this.$vToastify.success("Dil Eklendi", "Başarılı!");
          this.languageList.push({ title: language });
          language = null;
          document.querySelector("input[name=language]").value = "";
        } else {
          this.$vToastify.warning("Dil zaten listende var", "Uyarı!");
          language = null;
        }
      }
    },
    skillAdd(e) {
      let skill = document.querySelector("input[name=skill]").value;
      let bool = true;
      if (skill == null || skill == "") {
        this.$vToastify.success("Dil alanı boş girilemez", "Uyarı!");
      } else {
        this.skillList.forEach((t) => {
          if (t.title == skill) {
            bool = false;
          }
        });
        if (bool) {
          this.$vToastify.success("İlgi Alanı Eklendi", "Başarılı!");
          this.skillList.push({ title: skill });
          skill = null;
          document.querySelector("input[name=skill]").value = "";
        } else {
          this.$vToastify.warning("İlgi Alanı zaten listende var", "Uyarı!");
          skill = null;
        }
      }
    },
    certificateAdd(e) {
      let certificate = document.querySelector("input[name=certificate]").value;
      if (certificate == null || certificate == "") {
        this.$vToastify.success("Sertifika alanı boş girilemez", "Uyarı!");
      } else {
        let bool = true;
        this.certificateList.forEach((t) => {
          if (t.title == certificate) {
            bool = false;
          }
        });
        if (bool) {
          this.$vToastify.success("Sertifika Eklendi", "Başarılı!");
          this.certificateList.push({ title: certificate });
          certificate = null;
          document.querySelector("input[name=certificate]").value = "";
        } else {
          this.$vToastify.warning("Sertifika zaten listende var", "Uyarı!");
          certificate = null;
        }
      }
    },
    centerRemove(index) {
      this.centerList.splice(index, 1);
    },
    educationRemove(index) {
      this.educationList.splice(index, 1);
    },
    languageRemove(index) {
      this.languageList.splice(index, 1);
    },
    skillRemove(index) {
      this.skillList.splice(index, 1);
    },
    certificateRemove(index) {
      this.certificateList.splice(index, 1);
    },
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Bilgiler kayıt ediliyor..";
      let about = $("#content").summernote("code");
      let biographieSave = {
        about: about,
        skill: JSON.stringify(this.skillList),
        education: JSON.stringify(this.educationList),
        center: JSON.stringify(this.centerList),
        language: JSON.stringify(this.languageList),
        certificate: JSON.stringify(this.certificateList),
      };
      this.$store.dispatch("biographieUpdate", biographieSave).then((value) => {
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Biyografi Kaydet";
        if (value.type == "error") {
          if (value.message == "ERRxUAR") {
            this.$vToastify.warning(
              "Onay sürecindeki profil güncellenemez",
              "Uyarı!"
            );
          }
        } else {
          this.$vToastify.success(
            "Biyografi başarılı bir şekilde kaydedildi",
            "Başarılı!"
          );
        }
      });
    },
  },
  components: {},
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://terapivitrini.com/assets/plugins/summer-note/summernote1.js"
    );
    document.head.appendChild(recaptchaScript);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          // [groupName, [list of button]]
          ["style", ["bold", "italic", "underline", "clear"]],
          ["fontsize", ["fontsize"]],
          ["height", ["height"]],
          ["para", ["ul", "ol", "paragraph"]],
        ],
      });
    });
  },
};
</script>